import Model from '@/dc-it/models/Model'
// import AccountType from '@/dc-it/models/AccounType'
import Select from '@/dc-it/types/Select'
import Field from '@/dc-it/types/Field'
// import Relation from '@/dc-it/types/Relation'

export default class TransactionType extends Model {
    endpoint = 'transaction_types';

    actionTypes = {
      1: 'Depósito',
      2: 'Retiro',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      // new Relation('account_type', 'Tipo de cuenta', AccountType),
      new Field('name', 'Nombre'),
      new Select('action', 'Acción', this.actionTypes, '1'),
    ]

    clone = () => TransactionType;
}
