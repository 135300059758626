import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import BooleanField from '@/dc-it/types/BooleanField'
import Select from '@/dc-it/types/Select'

export default class AccountTypeField extends Model {
    endpoint = 'account_type_fields';

    types = {
      1: 'Texto',
      2: 'Número',
      3: 'Archivo',
      4: 'Fecha',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name', 'Nombre'),
      new Select('type', 'Tipo', this.types, '1'),
      new BooleanField('required', 'Requerido', 1),
    ]

    clone = () => AccountTypeField;
}
